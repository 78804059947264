import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Gallery from 'react-photo-gallery'
import styles from './CollectionPageTemplate.module.scss'
import MediaQuery from 'react-responsive'
import Lightbox from '../components/Lightbox/Lightbox'
import SEO from '../components/SEO/SEO'
import BackArrow from '../components/icons/BackArrow'
import Link from '../components/Link/Link'

export default function CollectionPage({ data, pageContext }) {
  const photos = data.paintings.edges.map(painting => {
    const id = painting.node.id
    const frontmatter = painting.node.frontmatter
    const image = painting.node.frontmatter.image.childImageSharp
    return {
      key: id,
      src: image.original.src,
      fluid: image.fluid,
      width: image.original.width,
      height: image.original.height,
      title: frontmatter.title,
      dimensions: frontmatter.sizes,
      status: frontmatter.status,
      details: frontmatter.details
    }
  })

  return (
    <>
      <SEO
        title={`Collection ${pageContext.collection}`}
        route={pageContext.slug}
      />
      <MediaQuery minWidth="40em">
        {matches => {
          const columns = matches ? 3 : 2
          return (
            <div className={styles.container}>
              <div className={styles.titleWrapper}>
                <Link to={'/works/'} className={styles.collectionLink}>
                  <div className={styles.iconWrapper}>
                    <BackArrow />
                  </div>
                  <h3>Other collections</h3>
                </Link>
                <h2 className={styles.collectionTitle}>
                  {pageContext.collection}
                </h2>
              </div>
              <div className={styles.gallery}>
                <Gallery
                  photos={photos}
                  direction="column"
                  columns={columns}
                  margin={5}
                  renderImage={({ index, photo, top, left, margin }) => {
                    const width = photo.width
                    if (photo && !photo.offline) {
                      const heightCm = photo.dimensions.heightCm
                      const widthCm = photo.dimensions.widthCm
                      const heightIn = photo.dimensions.heightIn
                      const widthIn = photo.dimensions.widthIn
                      return (
                        <Lightbox
                          key={photo.key}
                          id={photo.key}
                          content={
                            <div className={styles.contentWrapper}>
                              <div className={styles.infos}>
                                <h2 className={styles.title}>{photo.title}</h2>
                                <p className={styles.dash}>-</p>
                                <p>
                                  {`${heightCm} x ${widthCm} cm`}
                                  <span
                                    className={styles.grayText}
                                  >{` (${heightIn} x ${widthIn} in)`}</span>
                                </p>
                                {photo.details ? (
                                  <>
                                    <p className={styles.dash}>-</p>
                                    <p>{photo.details}</p>
                                  </>
                                ) : null}
                                {photo.status ? (
                                  <>
                                    <p className={styles.dash}>-</p>
                                    <p className={styles.grayText}>
                                      Private collection
                                    </p>
                                  </>
                                ) : null}
                              </div>
                              <Img
                                fluid={photo.fluid}
                                alt={photo.title}
                                title={photo.title}
                                style={{ marginTop: '1rem' }}
                                imgStyle={{ objectFit: 'contain' }}
                              ></Img>
                            </div>
                          }
                        >
                          <button
                            className={styles.button}
                            style={{
                              top: top,
                              left: left,
                              width: width,
                              position: 'absolute',
                              margin: margin
                            }}
                          >
                            <Img fluid={photos[index].fluid} />
                          </button>
                        </Lightbox>
                      )
                    } else {
                      return null
                    }
                  }}
                />
              </div>
            </div>
          )
        }}
      </MediaQuery>
    </>
  )
}

export const collectionPageQuery = graphql`
  query($collection: String!) {
    paintings: allMarkdownRemark(
      filter: {
        frontmatter: { collection: { eq: $collection }, offline: { eq: false } }
      }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            status
            offline
            details
            date
            image {
              childImageSharp {
                original {
                  src
                  width
                  height
                }
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            sizes {
              heightCm
              widthCm
              heightIn
              widthIn
            }
          }
        }
      }
    }
  }
`
