// modules
import React, { useState } from 'react'
import posed, { PoseGroup } from 'react-pose'
// styles
import styles from './Lightbox.module.scss'
// animations
import { animations } from './LightboxAnimations'

import CrossIcon from '../icons/CrossIcon'

// definitions
const Backdrop = posed.div(animations.Backdrop)
const LightboxContent = posed.div(animations.ImageWrapper)

function Lightbox({ children, id, content }) {
  // state
  const [isActive, setIsActive] = useState(false)
  // handlers
  const handleClick = () => setIsActive(!isActive)
  // jsx
  return (
    <div onClick={handleClick} style={{ cursor: 'pointer' }}>
      {children}
      <PoseGroup flipMove={false}>
        {isActive ? (
          <Backdrop key={id} className={styles.Backdrop} onClick={handleClick}>
            <LightboxContent className={styles.content}>
              <div className={styles.iconWrapper}>
                <CrossIcon />
              </div>
              {content}
            </LightboxContent>
          </Backdrop>
        ) : null}
      </PoseGroup>
    </div>
  )
}

// exports
export default Lightbox
