export const animations = {
  // Backdrop
  Backdrop: {
    enter: {
      applyAtStart: { display: 'block' },
      opacity: 1,
      transition: { duration: 100 },
      // delayChildren: 50,
    },
    exit: {
      applyAtEnd: { display: 'none' },
      opacity: 0,
      transition: { duration: 50 },
    },
  },
  // ImageWrapper
  ImageWrapper: {
    enter: {
      scale: 1,
    },
    exit: {
      scale: 0,
    },
  },
}
